.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}


body {
  font-family: 'Arial', sans-serif;
  background-color: #D5D5D5;
}


.container {
  padding-top: 20px;
}

.sleepyrat_dark {
  background-color: #5E5E5E;
  color: white;
}

.sleepyrat_mediumdark {
  background-color: rgb(146, 146, 146);
  color: black;
}

.sleepyrat_light {
  background-color: #D5D5D5;
  color: white;
}

.sleepyrat_green {
  background-color: #4CAF50;
  color: white;
}

.sleepyrat_transparent {
  /* background-color: rgba(16, 12, B8, 34); */
  background-color: rgba(16, 12, 128, 0.34);
  color: white;
}

.sleepyrat_red {
  background-color: #ED220D;
  color: white;
}

.sleepyrat_blue {
  background-color: rgb(13,17,49);
  color: white;
}

.no_link_decoration a {
  text-decoration: none;
  color: black;
}

.no_link_decoration2 a {
  text-decoration: none;
  color: white;
}

.footercontainer {
  display: inline-block;
  margin-right: 30px;
}

.logo{
  font-size: 25px;
  line-height: 70px;
}

.navtext{
  color: white;
  /* vertical-align: middle; */
  margin: auto;
}

.login-icon{
  margin: auto;
}

.projectslink{
  line-height: 70px;
}




/* login stuff */
.login-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 67vh;
}

.login-box {
  /* width: 80%; */
  background-color: white;
  display: flex;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  overflow: hidden;
}

.login-image {
  width: 40%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #f0f0f0;
}

.login-image img {
  max-width: 100%;
  height: auto;
}

.login-form {
  width: 80%;
  padding: 20px;
}

.form-group {
  margin-bottom: 1rem;
}

.form-control {
  width: 100%;
  padding: 0.375rem 0.75rem;
  margin-top: 0.5rem;
  font-size: 1rem;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

/* .btn-primary {
  color: #fff;
  background-color: #007bff;
  border-color: #007bff;
  margin-top: 1rem;
} */

/* .btn {
  display: inline-block;
  font-weight: 400;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  user-select: none;
  border: 1px solid transparent;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  line-height: 1.5;
  border-radius: 0.25rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
} */

/* Additional responsiveness for smaller screens */
@media (max-width: 768px) {
  .login-box {
    flex-direction: column;
  }

  .login-image, .login-form {
    width: 100%;
  }
}

.username{
  text-align: center;
}

.nobsgutter{
  --bs-gutter-x: 0;
}

.modal-content{
  background-color: white;
}

.limitmaxwidth{
  max-width: 700px;
  margin: auto;
}





.references_overview .references-item {
  /* border: 1px solid #c3c5cc; */
  border: 1px solid #010101;
  border-radius: 10px;
  margin: 1rem 0;
}

.references_overview .references-item-link {
  color: var(--dark);
  text-decoration: none;
  padding: .5rem;
  display: block;
}

.references_overview .references-item-link .content {
  display: grid;
  grid-template-columns: 1fr;
  align-items: center;
  /*grid-gap: 1rem;*/
  gap: 1rem;
  width: 100%;
}

.references_overview p, span {
  line-height: 1.6;
  font-weight: 300;
  text-wrap: pretty;
  font-size: 20px;
  display: inline-block;
  margin-left: 5px;
}

.references_overview .ff-title {
  font-family: Newsreader, serif;
}

.references_overview .references-item-link .date {
  justify-self: right;
  align-self: center;
  border-radius: 1rem;
  padding: .1875rem 1rem;
  font-size: .875rem;
}


.faq{
  /* larger font */
  font-size: 1.2rem;
}

*{
  font-family: 'Sora', sans-serif;
}